

































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { CareStation } from "@/views/master/carestation/types";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";
import { VForm } from "@/types";
import EditMixin from "@/mixins/editMixin";

@Component({
  components: {
    AppPlaces,
    OfficeSelectInput
  }
})
export default class CareStationForm extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin,
  EditMixin
) {
  @Ref("form") public readonly form!: VForm;

  public carestation: CareStation = {
    id: 0,
    name: "",
    furigana: "",
    medical_institution_code: "",
    office_code: "",
    post_code: "",
    pref: 0,
    city: "",
    area: "",
    building: "",
    tel_area: "",
    tel_city: "",
    tel_num: "",
    fax_area: "",
    fax_city: "",
    fax_num: "",
    personnel_fullname: "",
    personnel_furigana: "",
    personnel_last_name: "",
    personnel_last_name_kana: "",
    personnel_first_name: "",
    personnel_first_name_kana: "",
    care_station_offices: []
  };

  //選択済みの適応事業所
  public selectedOffice: number[] = [];

  public created(): void {
    if (this.$route.params.id) {
      this.getCareStation(Number(this.$route.params.id));
    } else {
      this.setLoaded();
    }
  }

  //訪問看護ステーション情報取得
  private getCareStation(id: number): void {
    this.postJsonCheck(
      window.base_url + "/api/master/carestation/getById",
      {
        carestation_id: id
      },
      res => {
        this.carestation = res.data.carestation;
        this.carestation.care_station_offices.forEach(office => {
          if (office.office_id) {
            this.selectedOffice.push(office.office_id);
          }
        });
        this.setLoaded();
      }
    );
  }

  public async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (
      this.carestation.office_code.slice(-7) !==
      this.carestation.medical_institution_code
    ) {
      if (
        !(await this.$openConfirm(
          "事業所番号の末尾7文字が、医療機関コードと異なります。よろしいですか？"
        ))
      ) {
        return;
      }
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.carestation.care_station_offices = [];
    this.selectedOffice.forEach(officeId => {
      this.carestation.care_station_offices.push({
        id: 0,
        office_id: officeId
      });
    });
    this.postJsonCheck(
      window.base_url + "/api/master/carestation/save",
      {
        carestation: this.carestation
      },
      () => {
        this.setNoEditMode();
        this.$router.push({ name: "CareStationEntry" });
      }
    );
  }

  public async deleteCareStation() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/carestation/delete",
      {
        carestation: this.carestation
      },
      () => {
        this.setNoEditMode();
        this.$router.push({ name: "CareStationEntry" });
      }
    );
  }

  //訪問看護ステーション名
  private requiredCarestationName(): boolean | string {
    return this.required(this.carestation.name, "必須です");
  }

  //ふりがな
  private requiredCarestationFurigana(): boolean | string {
    return this.required(this.carestation.furigana, "必須です");
  }

  /** 医療機関コードバリデーション */
  private ruleMedicalInstCode(): boolean | string {
    if (this.carestation.medical_institution_code.length !== 7) {
      return "半角数字7文字でご記入ください";
    }
    return true;
  }

  /** 事業所番号 */
  private ruleOfficeCode(): boolean | string {
    if (this.carestation.office_code.length !== 10) {
      return "半角数字10文字でご記入ください";
    }
    if (this.carestation.pref !== 0) {
      const prefName = this.prefName(this.carestation.pref);
      const prefCode = ("00" + this.carestation.pref).slice(-2);
      if (prefCode !== this.carestation.office_code.slice(0, 2)) {
        return `住所に${prefName}を選択している場合、先頭2文字は${prefCode}にしてください`;
      }
    }
    return true;
  }

  @Watch("carestation", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}

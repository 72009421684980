















































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Inquiry, DEFAULT_INQUIRY } from "#/model/inquiry";
import UtilMixin from "@/mixins/utilMixin";
import AnswerContent from "@/components/contact/AnswerContent.vue";
import InquiryFileViewer from "@/components/contact/InquiryFileViewer.vue";
import InquiryAnswerFileViewer from "@/components/contact/InquiryAnswerFileViewer.vue";
import { ACCESS_CONFIRMS } from "@/const/contact";

@Component({
  components: {
    AnswerContent,
    InquiryFileViewer,
    InquiryAnswerFileViewer
  }
})
export default class ContactView extends Mixins(UtilMixin) {
  /** 親問合せ */
  @Prop({ default: DEFAULT_INQUIRY }) parentInquiry!: Inquiry;
  /** 表示対象の問合せ */
  @Prop({ default: DEFAULT_INQUIRY }) inquiry!: Inquiry;

  /** 登録状況確認希望の定数 */
  private ACCESS_CONFIRMS = ACCESS_CONFIRMS;

  /** 表示用問合せ内容 */
  private get HtmlContact() {
    if (!this.inquiry.contact) {
      return "";
    }
    return this.inquiry.contact.replace(/\r?\n/g, "<br>");
  }
}

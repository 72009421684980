














































































import Component from "vue-class-component";
import { Prop, Emit, Watch } from "vue-property-decorator";
import AppAutocomplete from "../AppAutocomplete.vue";
import AppChatStaffSelector from "./AppChatStaffSelector.vue";
import AppChatSystemIcon from "./AppChatSystemIcon.vue";
import { Choice } from "../../types";
import AxiosMixin from "@/mixins/axiosMixin";
import { RoomType, FileType } from "./const";

interface Office {
  id: number;
  office_name: string;
  staffs: Staff[];
}

interface Staff {
  id: number;
  ewell_user_id: number;
  nickname: string;
  div_name: string;
  ibow_family_name: string;
  ibow_first_name: string;
  furigana: string;
  office_name: string;
}

@Component({
  components: { AppAutocomplete, AppChatStaffSelector, AppChatSystemIcon }
})
export default class AppChatRoomEditor extends AxiosMixin {
  @Prop() viewType!: number;
  @Prop({
    default: () => {
      return [];
    }
  })
  agreements!: Choice[];
  @Prop() iconPath?: string;
  @Prop({
    default: () => {
      return [];
    }
  })
  staffIds!: number[];
  @Prop() agreementId!: number | null;
  @Prop() talkTitle!: string;
  @Prop() roomType!: number;
  @Prop({ default: undefined }) fileType!: number | undefined;
  @Prop() selfNickname!: string;
  @Prop() selfEwellUserId!: number;
  @Prop() selfStaffId!: number;

  private ViewType = {
    create: 0,
    edit: 1
  } as const;

  private staffs: {
    id: number;
    ewellUserId: number;
    staffName: string;
    officeName: string;
  }[] = [];
  private innnerStaffIds: number[] = [];
  private innerTalkTitle = "";
  private innerAgreementId: number | null = null;
  private selectedStaffList: {
    id: number;
    name: string;
    ewellUserId: number;
  }[] = [];
  private isOpenExitDialog = false;
  private localImage: File | [] = [];
  private tmpObjectUrl: string | null = null;
  private signImagePath = "";
  private isFetching = true;

  @Watch("innerAgreementId")
  private agreementDidChange() {
    // 法人の変更があった場合、従業員の一覧をリセットする
    this.staffs = [];

    if (this.innerAgreementId === null) {
      return;
    }

    if (this.viewType === this.ViewType.create) {
      this.innnerStaffIds = [];
    }

    this.isFetching = true;
    this.postJsonBackground(
      window.chat_backend_url + "/api/chat/staffs-of-corp/get",
      { agreement_id: this.innerAgreementId },
      res => {
        this.staffs = res.data.offices
          .flatMap((office: Office) => {
            return office.staffs;
          })
          .sort((a: Staff, b: Staff) => {
            // 職員は ふりがな降順 で表示する
            return a.furigana < b.furigana ? -1 : 1;
          })
          .map((staff: Staff) => {
            return {
              id: staff.id,
              ewellUserId: staff.ewell_user_id,
              staffName: staff.nickname,
              officeName: staff.office_name
            };
          });

        this.isFetching = false;
      }
    );
  }

  created() {
    this.innerAgreementId = this.agreementId;
    this.innnerStaffIds = [...this.staffIds];
    this.innerTalkTitle = this.talkTitle;

    if (this.iconPath) {
      this.postJsonBackground(
        window.auth_backend_url + "/api/sign-image-path/get",
        {
          bucket_path: this.iconPath
        },
        res => {
          this.signImagePath = res.data.image_path;
        }
      );
    }
  }

  destroy() {
    if (this.tmpObjectUrl) {
      URL.revokeObjectURL(this.tmpObjectUrl);
    }
  }

  private get SubmitButtonTitle() {
    return this.viewType === this.ViewType.create ? "作成" : "更新";
  }

  private get isEnabledSubmitButton() {
    // 一人でもトークルームの作成は可能
    return this.innerTalkTitle.length > 0 && this.innerAgreementId !== null;
  }

  private get isSystemRoom() {
    return this.roomType === RoomType.system;
  }

  /** AI自動作成通知用トークルーム判定 */
  private get IsAiReportChatRoom(): boolean {
    return this.fileType === FileType.aiReport;
  }

  private get UploadedImage() {
    const isArray = Array.isArray(this.localImage);
    if (isArray || !this.localImage) {
      return null;
    } else {
      if (this.tmpObjectUrl) {
        URL.revokeObjectURL(this.tmpObjectUrl);
      }
      this.tmpObjectUrl = URL.createObjectURL(this.localImage);
      return this.tmpObjectUrl;
    }
  }

  private staffChanged(
    staffList: { id: number; name: string; ewellUserId: number }[]
  ) {
    this.selectedStaffList = staffList;
  }

  private async openExitDialog() {
    if (
      !(await this.$openConfirm(
        "退出すると、トークルームが見えなくなります\nよろしいですか？"
      ))
    ) {
      return;
    }

    this.exit();
  }

  private didClickChangeImageButton() {
    const input = document.getElementById("app-chat-room-editor-image-input");

    if (input) {
      input.click();
    }
  }

  private selectRoomImage(file?: File) {
    if (!file) return;

    if (file.size > 1e7) {
      this.localImage = [];
      this.$openAlert("ファイルサイズが10MBを超えています");
    }
  }

  private async saveRoom() {
    // 差分チェック
    if (this.viewType === this.ViewType.edit) {
      const diff = this.checkDiff();

      // トーク名の変更・職員の追加・職員の削除のいずれかがあった場合、確認ダイアログを表示する
      if (
        diff.hasTitleDiff ||
        diff.hasImageDiff ||
        diff.addStaffNames.length > 0 ||
        diff.removeStaffNames.length > 0
      ) {
        let line = 0;
        let dialogText = "以下の変更が行われます\n\n";
        if (diff.hasTitleDiff) {
          dialogText += `トーク名が ${this.innerTalkTitle} に変更されます`;
          line++;
        }

        if (diff.hasImageDiff) {
          dialogText += "トーク画像が変更されます";
          line++;
        }

        for (const addStaffName of diff.addStaffNames) {
          // 最大表示数は5件
          if (line > 4) {
            break;
          }

          if (line > 0) {
            dialogText += "\n";
          }

          dialogText += `${addStaffName} さんが追加されます`;
          line++;
        }

        for (const removeStaffName of diff.removeStaffNames) {
          if (line > 4) {
            break;
          }

          if (line > 0) {
            dialogText += "\n";
          }

          dialogText += `${removeStaffName} さんが削除されます`;
          line++;
        }

        const changedItemsCount =
          (diff.hasTitleDiff ? 1 : 0) +
          diff.addStaffNames.length +
          diff.removeStaffNames.length;
        if (changedItemsCount - line > 0) {
          dialogText += `\n\n他${changedItemsCount - line}件の変更があります`;
        }

        if (!(await this.$openConfirm(dialogText))) {
          return;
        }
      }
    }

    this.submit();
  }

  // ルームの差分チェック
  // return: ルームタイトル差分があるかどうか、追加されたスタッフ名、削除されたスタッフ名のタプル
  private checkDiff(): {
    hasTitleDiff: boolean;
    hasImageDiff: boolean;
    addStaffNames: string[];
    removeStaffNames: string[];
  } {
    const hasTitleDiff = this.talkTitle !== this.innerTalkTitle;

    const isArray = Array.isArray(this.localImage);
    // 配列（空）のまま or undefined で画像未設定と判断
    const hasImageDiff = !(isArray || !this.localImage);

    const addStaffNames = this.selectedStaffList
      .filter(selectedStaff => {
        return !this.staffIds.includes(selectedStaff.id);
      })
      .map(selectedStaff => {
        return selectedStaff.name;
      });

    const removeStaffNames = this.staffIds
      .filter(staffId => {
        const isRemoved = !this.selectedStaffList.some(selectedStaff => {
          return selectedStaff.id === staffId;
        });

        if (isRemoved) {
          const staff = this.staffs.find(staff => {
            return staff.id === staffId;
          });

          // 自分は選べない => 常に削除と同じ状態になるため除外して考える
          if (staff) {
            return staff.ewellUserId !== this.selfEwellUserId;
          }
          // staff が見つからない => 退職 or iBowアカウントの連携が解除された
          // 選択されていないので削除されるが、変更差分として扱う必要はないので false で OK
        }

        return false;
      })
      .map(staffId => {
        const staff = this.staffs.find(staff => {
          return staff.id === staffId;
        });

        if (staff) {
          return staff.staffName;
        }

        return "";
      });

    return { hasTitleDiff, hasImageDiff, addStaffNames, removeStaffNames };
  }

  @Emit()
  private submit() {
    const isArray = Array.isArray(this.localImage);

    const data = {
      isCreate: this.viewType === this.ViewType.create,
      title: this.innerTalkTitle,
      staffList: [
        {
          id: this.selfStaffId,
          name: this.selfNickname,
          ewellUserId: this.selfEwellUserId
        },
        ...this.selectedStaffList
      ],
      agreementId: this.innerAgreementId
    } as { [key: string]: unknown };

    if (isArray || !this.localImage) {
      // 配列（空）のまま or undefined で画像未設定と判断

      if (this.iconPath) {
        // 画像登録済みの場合は引き継ぎ
        data.imagePath = this.iconPath;
      }
    } else {
      data.image = this.localImage;
    }

    return data;
  }

  @Emit()
  private exit() {
    return;
  }
}

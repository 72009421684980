import { Component, Mixins } from "vue-property-decorator";
import { COLLECTION_AI_GENERATE_STATE } from "@/const/envFireStore";
import { AiGenerateHistory } from "@/views/patient/plan/types";
import AxiosMixin from "@/mixins/axiosMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import * as CONST from "@/components/patient/common/aireport/const";
import * as appDate from "#/utility/appDate";

/** AI書類自動作成用の共通処理 */
@Component
export default class AiReportMixin extends Mixins(
  AxiosMixin,
  FireStoreMixin,
  UtilMixin
) {
  /** 変数 */

  /** リアルタイムアップデート用リスナーをデタッチする関数 */
  protected detach = () => {
    return;
  };

  /** AI自動作成履歴 */
  protected generateHistories: AiGenerateHistory[] = [];

  /** AI自動作成書類タイプ */
  protected report_type = 0;

  /** getter */

  /** AI自動作成中フラグ */
  protected get IsAiGenerating(): boolean {
    // 1件もない場合は作成中ではない
    if (this.generateHistories.length === 0) {
      return false;
    }

    const latestHistory = this.generateHistories[0];

    // 最新が「作成中」状態かどうか
    if (latestHistory.state != CONST.AI_GENERATE_STATE_PROGRESS) {
      return false;
    }

    // 「作成中」状態だが、一定時間経過している場合は作成中ではないとみなす（ステータスの更新失敗に対する保険）
    const latest = appDate.strToDateObj(latestHistory.datetime);
    const now = appDate.strToDateObj();
    if (appDate.diffMinute(now, latest) >= 60 * 3) {
      return false;
    }

    return true;
  }

  /** 関数 */

  created() {
    this.collection = COLLECTION_AI_GENERATE_STATE;
  }

  beforeDestroy() {
    // リアルタイムアップデート用リスナーをデタッチ
    this.detach();
  }

  /** リアルタイムアップデート用リスナーの作成 */
  protected createAiReportListener(
    patientId: number,
    reportType: number,
    fetchFunc: (obj: { tab: number; page: number }) => void
  ) {
    this.detach = this.getListner(
      query => {
        return query
          .where("agreement_id", "==", this.loginUser.agreement_id)
          .where("patient_id", "==", patientId)
          .where("report_type", "==", reportType)
          .orderBy("datetime", "asc");
      },
      docChange => {
        const index = this.generateHistories.findIndex(item => {
          return item.id === docChange.doc.id;
        });

        const stateData = docChange.doc.data() as AiGenerateHistory;
        stateData.id = docChange.doc.id;

        switch (docChange.type) {
          // 新規ドキュメント追加(=新しく自動作成)
          case "added": {
            // 履歴リストに項目追加
            this.generateHistories.unshift(stateData);
            break;
          }
          // ドキュメント更新(=自動作成完了or失敗)
          case "modified": {
            if (index === -1) {
              break;
            }

            const beforeState = this.generateHistories[index].state;
            const afterState = stateData.state;

            // 再取得して一覧画面を更新
            // ・作成中>作成完了
            // ・「未提出」タブ
            if (
              beforeState == CONST.AI_GENERATE_STATE_PROGRESS &&
              afterState == CONST.AI_GENERATE_STATE_SUCCESS
            ) {
              if (
                !this.$route.query.reporttab ||
                this.$route.query.reporttab === "0"
              ) {
                fetchFunc({ tab: 0, page: 0 });
              }
            }

            // 履歴リストの項目更新
            this.generateHistories.splice(index, 1, stateData);

            break;
          }
          // ドキュメント削除(=履歴削除)
          case "removed": {
            // 履歴は削除できない
          }
        }
      }
    );
  }
}

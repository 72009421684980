import { Component, Mixins } from "vue-property-decorator";
import { Choice } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class LinkStaffMixin extends Mixins(AxiosMixin) {
  /** iBowID */
  public INVITE_ID = 1;
  /** メールアドレス */
  public INVITE_MAIL = 2;
  /** 電話番号 */
  public INVITE_PHONE = 3;
  /** 未招待 */
  public INVITE_NO = 4;

  /** 招待種類 */
  public inviteType = this.INVITE_ID;

  /** チェック済みの招待内容（重複チェック防止用） */
  public checkIbowSendInfo = "";

  /** 招待種類リスト */
  public inviteTypes: Choice[] = [
    { text: "iBowID", value: this.INVITE_ID },
    { text: "メールアドレス", value: this.INVITE_MAIL },
    { text: "電話番号", value: this.INVITE_PHONE },
    { text: "未招待", value: this.INVITE_NO }
  ];

  /** 招待種類リスト（所長再連携用） */
  public inviteTypesAgain: Choice[] = [
    { text: "iBowID", value: this.INVITE_ID },
    { text: "メールアドレス", value: this.INVITE_MAIL },
    { text: "電話番号", value: this.INVITE_PHONE }
  ];

  /** 招待内容ラベル文言 */
  public get InviteMessage() {
    switch (this.inviteType) {
      case this.INVITE_ID:
        return "*iBowID";
      case this.INVITE_MAIL:
        return "*メールアドレス";
      case this.INVITE_PHONE:
        return "*電話番号";
    }
    return "";
  }

  /** 招待ルール */
  public ruleInvite(ibow_send_info: string): boolean | string {
    if (!ibow_send_info) {
      return "必須です";
    }

    let ok = true;
    let message = "";
    switch (this.inviteType) {
      case this.INVITE_MAIL:
        if (ibow_send_info.indexOf("@") < 0) {
          ok = false;
          message =
            "招待用メールアドレスの形式が不正です。「@」を入力してください。";
        }
        break;
      case this.INVITE_PHONE:
        if (!ibow_send_info.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
          ok = false;
          message =
            "招待用携帯電話番号の形式が不正です。「-」なしで数値のみで入力してください。";
        }
        break;
    }
    return ok || message;
  }
}

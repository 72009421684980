import Base from "../base";

export interface MedicalInstitution extends Base {
  id: number;
  agreement_id: number;
  medical_institution_code: string;
  office_code: string;
  name: string;
  furigana: string;
  post_code: string;
  pref: number;
  city: string;
  area: string;
  building: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
  fax_area: string;
  fax_city: string;
  fax_num: string;
  honobono_hosp_id: number;
  doctors: Doctor[];
  medical_institution_offices: MedicalInstitutionOffice[];
}

export const DefaultMedicalInstitution = (): MedicalInstitution => ({
  id: 0,
  agreement_id: 0,
  medical_institution_code: "",
  office_code: "",
  name: "",
  furigana: "",
  post_code: "",
  pref: 0,
  city: "",
  area: "",
  building: "",
  tel_area: "",
  tel_city: "",
  tel_num: "",
  fax_area: "",
  fax_city: "",
  fax_num: "",
  honobono_hosp_id: 0,
  doctors: [],
  medical_institution_offices: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

export interface Doctor extends Base {
  /** ID */
  id: number;
  /** 医療機関ID */
  medical_institution_id: number;
  /** 医療機関名 */
  medical_institution_name?: string;
  /** 空データフラグ\n0:医師未登録\n1:医師登録 */
  is_empty: number;
  /** 担当科 */
  section_name: string;
  /** 氏名 */
  full_name: string;
  /** ふりがな */
  furigana: string;
  /** 担当者姓 */
  last_name: string;
  /** 担当者姓ふりがな */
  last_name_kana: string;
  /** 担当者名 */
  first_name: string;
  /** 担当者名ふりがな */
  first_name_kana: string;
  /** ステータス\n0:空データ\n1:在職\n2:休職\n3:退職 */
  status: number;
  /** 留意事項 */
  remarks: string;
  /** ほのぼの医師ID */
  honobono_hosp_dr_id: number;
  /** 連絡先 */
  doctor_contacts: DoctorContact[];
}

export const DefaultDoctor = (): Doctor => ({
  id: 0,
  medical_institution_id: 0,
  is_empty: 1,
  section_name: "",
  full_name: "",
  furigana: "",
  last_name: "",
  last_name_kana: "",
  first_name: "",
  first_name_kana: "",
  status: 1,
  remarks: "",
  honobono_hosp_dr_id: 0,
  doctor_contacts: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

export interface DoctorContact extends Base {
  /** ID */
  id: number;
  /** 医師ID */
  doctor_id: number;
  /** 連絡先種別 \n0:未選択\n1:携帯電話\n2:自宅電話\n3:自宅FAX\n4:email\n5:携帯メール\n6:その他 */
  contact_type: number;
  /** 連絡先情報 */
  contact_info: string;
}

export const DefaultDoctorContact = (): DoctorContact => ({
  id: 0,
  doctor_id: 0,
  contact_type: 0,
  contact_info: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

export interface MedicalInstitutionOffice {
  /** ID */
  id: number;
  /** 医療機関ID */
  medical_institution_id: number;
  /** 事業所ID */
  office_id: number;
}

/** AI自動作成履歴 */
export interface AiGenerateHistory {
  /** ID */
  id: string;
  /** iBowアカウントID */
  ibow_user_id: number;
  /** 契約ID */
  agreement_id: number;
  /** 作成職員ID */
  staff_id: number;
  /** 作成職員事業所ID */
  staff_office_id: number;
  /** 作成職員名 */
  staff_name: string;
  /** 利用者ID */
  patient_id: number;
  /** 利用者事業所ID */
  patient_office_id: number;
  /** 利用者名 */
  patient_name: string;
  /** 利用者ふりがな */
  patient_name_kana: string;
  /** 作成日時 */
  datetime: string;
  /** 作成日時(和暦) */
  datetime_wareki: string;
  /** 作成状況 */
  state: number;
  /** 書類種別 1:看護計画書 2:月次報告書 */
  report_type: number;
  /** 書類ID */
  report_id: number;
  /** 対象年月(yyyyMM) */
  yearmonth: number;
  /** 類似検索でヒットしたコンテキスト情報 */
  context: string;
  /** 最終プロンプト */
  prompt: string;
  /** 回答原文 */
  answer: string;
}

export const DefaultAiGenerateHistory = (): AiGenerateHistory => ({
  id: "",
  ibow_user_id: 0,
  agreement_id: 0,
  staff_id: 0,
  staff_office_id: 0,
  staff_name: "",
  patient_id: 0,
  patient_office_id: 0,
  patient_name: "",
  patient_name_kana: "",
  datetime: "",
  datetime_wareki: "",
  state: 0,
  report_type: 0,
  report_id: 0,
  yearmonth: 0,
  context: "",
  prompt: "",
  answer: ""
});

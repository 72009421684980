

























































































































































































































































import { Component, Watch } from "vue-property-decorator";
import OneMonthAddV5Covit from "./OneMonthAddV5Covit.vue";

@Component
export default class OneMonthAddV6 extends OneMonthAddV5Covit {
  created(): void {
    this.setHour24DivV2();
    this.setMedicalSpecialDivV5();
    this.setMedicalTerminalDiv();
    this.setAccompaniedStaffCnt();
  }

  //24時間対応体制加算
  @Watch("hour24_div1") protected handleMedicalHour24Div1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.hour24_div1 == true) {
      this.receiptVisitRecord.hour24_div = 1;
      this.hour24_div2 = false;
    } else {
      if (this.hour24_div2 == false) {
        this.receiptVisitRecord.hour24_div = 0;
      }
    }
  }
  @Watch("hour24_div2") protected handleMedicalHour24Div2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.hour24_div2 == true) {
      this.receiptVisitRecord.hour24_div = 3;
      this.hour24_div1 = false;
    } else {
      if (this.hour24_div1 == false) {
        this.receiptVisitRecord.hour24_div = 0;
      }
    }
  }

  // 訪問看護医療DX情報活用加算
  protected disableCheckDxInfoUtilFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_dx_info_util_add == 0 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  // 24時間対応体制
  protected setHour24DivV2() {
    //継承元のOneMonthAdd.setHour24Divを通ってくるので、予期せぬ値にならないように初期化
    this.hour24_div1 = false;
    this.hour24_div2 = false;

    if (this.receiptVisitRecord.hour24_div == 1) {
      this.hour24_div1 = true;
    }
    if (this.receiptVisitRecord.hour24_div == 3) {
      this.hour24_div2 = true;
    }
  }

  // 24時間対応体制（イ）
  protected disableCheckMedicalHour24Div1Add(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_is_hour24_taiou_reduce_burden != 1 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }

    return result;
  }

  // 24時間対応体制（ロ）
  protected disableCheckMedicalHour24Div2Add(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_hour24_taiou_add != 1 ||
      this.officeHistory.medical_is_hour24_taiou_reduce_burden == 1 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }

    return result;
  }

  // ベースアップ評価料
  protected disableCheckBaseUp1Flg(): boolean {
    let result = false;
    if (this.officeHistory.medical_base_up1 == 0 || this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  // ベースアップ評価料（Ⅱ）表示用
  protected baseUp2Text(): string {
    let base_up2_text = "";
    if (this.officeHistory.medical_base_up2 > 0) {
      base_up2_text =
        "ベースアップ評価料（Ⅱ）" + this.officeHistory.medical_base_up2;
    }
    return base_up2_text;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV6() {
    //ターミナルケアと遠隔死亡診断補助
    if (this.disableCheckMedicalTerminalDivV4()) {
      this.medical_terminal_div1 = false;
      this.medical_terminal_div2 = false;
      this.receiptVisitRecord.medical_terminal_div = 0;
      this.receiptVisitRecord.remote_death_assist = 0;
    }
    //特別管理指導
    if (this.disableCheckSpecialInstFlg()) {
      this.receiptVisitRecord.special_inst_flg = 0;
    }
    //24時間体制
    if (this.disableCheckMedicalHour24Add()) {
      this.hour24_div1 = false;
      this.hour24_div2 = false;
      this.receiptVisitRecord.hour24_div = 0;
    }
    //特別管理加算
    if (
      this.disableCheckMedicalSpecialDiv1() &&
      this.disableCheckMedicalSpecialDiv2() &&
      this.disableCheckMedicalSpecialDiv3()
    ) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = false;
      this.medical_special_div3 = false;
      this.receiptVisitRecord.medical_special_div = 0;
    }
    //在宅患者連携指導
    if (this.disableCheckHomeInstFlg()) {
      this.receiptVisitRecord.home_inst_flg = 0;
    }
    //精神科重症患者支援管理連携加算
    if (this.disableCheckMentalSupportDivV2()) {
      this.mental_support_div1 = false;
      this.mental_support_div2 = false;
      this.receiptVisitRecord.mental_support_div = 0;
    }
    //情報提供療養費
    if (this.disableCheckInfoExpensesFlgV2()) {
      this.receiptVisitRecord.info_expenses_flg1 = 0;
      this.receiptVisitRecord.info_expenses_flg2 = 0;
      this.receiptVisitRecord.info_expenses_flg3 = 0;
    }
    //情報提供療養費（II）回数
    if (this.disableCheckInfoExpenses2Count()) {
      this.receiptVisitRecord.info_expenses2_count = 0;
    }
    //看護・介護職員連携
    if (this.disableCheckNursingCareFlg()) {
      this.receiptVisitRecord.nursing_care_flg = 0;
    }
    //看護・介護職員連携日
    if (this.disableCheckNursingCareDate()) {
      this.receiptVisitRecord.nursing_care_date = "";
    }
    //専門管理加算
    if (this.disableCheckSpecialManagementAddV4()) {
      this.receiptVisitRecord.special_management_add = 0;
    }
    // DX情報活用加算
    if (this.disableCheckDxInfoUtilFlg()) {
      this.receiptVisitRecord.dx_info_util_flg = 0;
    }
    // ベースアップ評価料
    if (this.disableCheckBaseUp1Flg()) {
      this.receiptVisitRecord.base_up1_flg = 0;
    }
  }
}

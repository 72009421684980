import Base from "../base";

/* 事業所履歴 訪問超過減算 対象加算 */
export interface OfficeHistoryOverTherapistTargetAddition extends Base {
  id: number; //ID
  office_history_id: number; //事業所履歴ID
  fiscal_year: number; //年度
  yearmonth: number; //年月
  is_uncalculated: number; //未計算フラグ
}

export const DefaultOfficeHistoryOverTherapistTargetAddition = (): OfficeHistoryOverTherapistTargetAddition => ({
  id: 0,
  office_history_id: 0,
  fiscal_year: 0,
  yearmonth: 0,
  is_uncalculated: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
